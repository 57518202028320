import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {addEventCharity, editCharityEvent} from '../../../app/modules/auth/core/_requests'
import {KTIcon} from '../../../_metronic/helpers'
import moment from 'moment'
import {ToastContainer, toast} from 'react-toastify'
import getYouTubeID from 'get-youtube-id'
import DatePicker from 'react-datepicker'

const isValidUrl = (value: string | undefined) => {
  try {
    new URL(value || '')
    return true
  } catch (error) {
    return false
  }
}

const validationSchema = Yup.object({
  name: Yup.string().required('Title  is required'),
  startDate: Yup.string().required('Start Date is required'),
  endDate: Yup.string().required('End Date is required'),
  location: Yup.string().required('Location is required'),
  description: Yup.string().required('Description is required'),
  eventLink: Yup.string()
    .test('is-valid-url', 'Invalid URL format', isValidUrl)
    .required('Link is required'),

  starttime: Yup.string()
    .required('Start Time is required')
    .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Invalid time format (HH:mm)')
    .test('is-valid-time', 'Start time should not be in the past', function (value) {
      const startDate = this.parent.startDate
      const currentDate = new Date()
      const selectedDateTime = new Date(`${startDate}T${value}`)

      return selectedDateTime >= currentDate
    }),

  endtime: Yup.string()
    .required('End Time is required')
    .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Invalid time format (HH:mm)')
    .test(
      'is-valid-end-time',
      'End time must be at least 30 minutes after Start Time',
      function (value) {
        const startDateTime = new Date(`${this.parent.startDate} ${this.parent.starttime}`)
        const endDateTime = new Date(`${this.parent.endDate} ${value}`)
        const thirtyMinutesLater = new Date(startDateTime.getTime() + 30 * 60000)
        return endDateTime >= thirtyMinutesLater && endDateTime >= startDateTime
      }
    ),
})

const AddEditEventBody = ({selectedRow, handleClose, setLoader}) => {
  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState<File[]>([])
  const [seqCode, setSeqCode] = useState<number | undefined>(0)
  const [editImage, setEditImage] = useState([])
  const [youtubeUrl, setYouTubeUrl] = useState([])
  const [youtubeLinks, setYoutubeLinks] = useState<string[]>([])
  const [inputValue, setInputValue] = useState<string>('')
  const [youtubeInput, setyoutubeInput] = useState(false)
  const [showImage, setShowImage] = useState(false)
  const [edit, setEdit] = useState(false)
  const [youtubeError, setYoutubeError] = useState('')
  const seqCodes = editImage
    .map((item: any) => item?.seqCode)
    .concat(youtubeUrl.map((item: any) => item?.seqCode))
  const maxSeqCode = Math.max(...seqCodes.map(Number), 0)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    setYoutubeError('')
  }

  const imageCount = editImage.length + images.length || 0
  const weburCount = youtubeLinks.length + youtubeUrl.length

  const handleAddLink = () => {
    try {
      const urlValidationSchema = Yup.string().matches(
        /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(\S*?)$/,
        'Enter a valid YouTube video URL'
      )
      urlValidationSchema.validateSync(inputValue)
      setYoutubeError('')
    } catch (error) {
      setYoutubeError('Enter a valid YouTube video URL')
      return
    }

    if (weburCount >= 7) {
      toast.error('Cannot add more than 7 YouTube Link.')
      return
    }
    if (inputValue.trim() !== '') {
      setSeqCode((prevSeqCode) => (prevSeqCode !== undefined ? prevSeqCode + 1 : 1))
      const currentSeqCode = seqCode !== undefined ? seqCode + 1 : 1
      const youtubeLink = `w${currentSeqCode}_` + inputValue
      setYoutubeLinks((prevLinks) => [...prevLinks, youtubeLink])
      setInputValue('')
    }
  }

  const generateThumbnail = (url) => {
    const extractedCode = getYouTubeID(url)
    return `https://img.youtube.com/vi/${extractedCode}/hqdefault.jpg` || null
  }

  let imageResult = youtubeLinks.map((link) => generateThumbnail(link))

  function convertToAMPM(timeString) {
    const [hour, minute] = timeString.split(':').map(Number)

    if (hour >= 12) {
      const ampmHour = hour === 12 ? hour : hour - 12
      const period = 'PM'
      return `${ampmHour}:${minute < 10 ? '0' : ''}${minute} ${period}`
    } else {
      const period = 'AM'
      return `${hour}:${minute < 10 ? '0' : ''}${minute} ${period}`
    }
  }

  const handleAddEvent = async (values) => {
    try {
      setLoading(true)
      setLoader(true)
      const formData = new FormData()
      formData.append('name', values.name)
      formData.append('startdate', values.startDate)
      formData.append('enddate', values.endDate)
      formData.append('location', values.location)
      formData.append('description', values.description)
      formData.append('eventLink', values.eventLink)
      formData.append('starttime', convertToAMPM(values.starttime))
      formData.append('endtime', convertToAMPM(values.endtime))
      images.forEach((image, index) => {
        formData.append('images', image)
      })
      youtubeLinks.forEach((link, index) => {
        formData.append('weburl', link)
      })
      const response = await addEventCharity(formData)
      if (response.status === 202) {
        toast.success(response?.data?.message || 'Event is added successfully')
        handleClose()
        setTimeout(() => {
          window.location.reload()
        }, 2000)
        formik.resetForm()
        setImages([])
        setEditImage([])
        setYouTubeUrl([])
        setYoutubeLinks([])
      } else {
        toast.error(response.data.message)
      }
    } catch (error: any) {
      toast.error(error.response.data.message || 'Error adding post')
    } finally {
      setLoading(false)
      setLoader(false)
    }
  }

  const handleEditEvent = async (values) => {
    try {
      setLoading(true)
      setLoader(true)
      const formData = new FormData()
      formData.append('name', values.name)
      formData.append('startdate', values.startDate)
      formData.append('enddate', values.endDate)
      formData.append('location', values.location)
      formData.append('description', values.description)
      formData.append('eventLink', values.eventLink)
      formData.append('starttime', convertToAMPM(values.starttime))
      formData.append('endtime', convertToAMPM(values.endtime))
      images.forEach((image, index) => {
        formData.append('images', image)
      })
      youtubeLinks.forEach((link, index) => {
        formData.append('weburl', link)
      })
      formData.append('seqCode', JSON.stringify(seqCodes))
      const response = await editCharityEvent(formData, selectedRow.row.id)
      if (response && response.status === 202) {
        toast.success(response?.data?.message || 'Event is Edited successfully')
        handleClose()
        setTimeout(() => {
          window.location.reload()
        }, 2000)
        formik.resetForm()
        setImages([])
        setEditImage([])
        setYouTubeUrl([])
        setYoutubeLinks([])
      }
    } catch (error: any) {
      toast.error(error.response.data.message || 'Error in Edit Event')
    } finally {
      setLoading(false)
      setLoader(false)
    }
  }

  const formatDate = (inputDate) => {
    const date = moment(inputDate, 'YYYY-MM-DD')
    return date.isValid() ? date.format('DD/MM/YYYY') : ''
  }

  const parseDateToYYYYMMDD = (inputDate) => {
    const date = moment(inputDate, 'DD/MM/YYYY')
    return date.isValid() ? date.format('YYYY-MM-DD') : ''
  }
  const convertTo24HourFormat = (time) => {
    if (!time) {
      return ''
    }
    const format = 'hh:mm A'
    const parsedTime = moment(time, format)
    if (!parsedTime.isValid()) {
      return time
    }
    const formattedTime = parsedTime.format('HH:mm')
    return formattedTime
  }
  const formik = useFormik({
    initialValues: {
      name: '',
      startDate: '',
      location: '',
      description: '',
      eventLink: '',
      weburl: [],
      starttime: '',
      endDate: '',
      endtime: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, {setErrors}) => {
      const currentDate = new Date()
      const currentDateString = currentDate.toISOString().split('T')[0]
      const currentTimeString = currentDate.toLocaleTimeString('en-US', {hour12: false})

      if (
        values.startDate < currentDateString ||
        (values.startDate === currentDateString && values.starttime <= currentTimeString)
      ) {
        toast.error('You cannot edit ongoing or closed events.')
        return
      }

      const startDateTime = new Date(`${values.startDate} ${values.starttime}`)
      const endDateTime = new Date(`${values.endDate} ${values.endtime}`)

      if (endDateTime < startDateTime) {
        setErrors({
          endDate: 'End date must be equal to or later than the start date.',
        })
        return
      }
      if (imageCount === 0) {
        setShowImage(true)

        return
      }
      if (Object.keys(selectedRow).length > 0) {
        handleEditEvent(values)
      } else {
        handleAddEvent(values)
      }
      setLoading(true)
    },
  })

  useEffect(() => {
    if (Object.keys(selectedRow).length > 0) {
      setyoutubeInput(true)
      setEdit(true)
      const formattedDate = formatDate(selectedRow?.row?.startDate)
      const formattedEndDate = formatDate(selectedRow?.row?.endDate)

      formik.setValues({
        name: selectedRow?.row?.title || '',
        startDate: parseDateToYYYYMMDD(formattedDate) || '',
        endDate: parseDateToYYYYMMDD(formattedEndDate) || '',
        eventLink: selectedRow?.row?.eventLink || '',
        location: selectedRow?.row?.location || '',
        description: selectedRow?.row?.description || '',
        endtime: convertTo24HourFormat(selectedRow?.row?.endtime) || '',
        starttime: convertTo24HourFormat(selectedRow?.row?.starttime) || '',
        weburl:
          selectedRow?.row?.media
            .map((item) => item.thumbnailURL)
            .filter((thumbnailURL) => thumbnailURL !== null) || '',
      })
      setYouTubeUrl(
        selectedRow?.row?.media
          .map((item) => item)
          .filter((thumbnailURL) => thumbnailURL.thumbnailURL !== null)
      )

      setEditImage(
        (selectedRow?.row?.media || [])
          .filter((item) => item.thumbnailURL === null)
          .map((item) => item) || []
      )
    }
  }, [selectedRow])

  useEffect(() => {
    setSeqCode((prevSeqCode) => maxSeqCode)
  }, [maxSeqCode])

  const handlePreviewImage = (index) => {
    const updatedImages = editImage.filter((_, i) => i !== index)
    setEditImage(updatedImages)
  }

  const handleUploadImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index)
    setImages(updatedImages)
  }

  const handlePreviewWeburl = (index) => {
    const updatedWebUrl = youtubeUrl.filter((_, i) => i !== index)
    setYouTubeUrl(updatedWebUrl)
  }

  const handleUploadWeburl = (index) => {
    const updatedWebUrl: any = youtubeLinks.filter((_, i) => i !== index)
    setYoutubeLinks(updatedWebUrl)
  }

  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(today.getDate() + 1)

  const hasDateTime = formik.values.startDate && formik.values.starttime
  let isPast
  if (hasDateTime && Object.keys(selectedRow).length > 0) {
    const currentDateTime = new Date()
    const currentDate = currentDateTime.toISOString().split('T')[0]
    const currentTime = currentDateTime
      .toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'})
      .slice(0, 5) // Extract HH:mm

    const selectedDateTime = new Date(formik.values.startDate + 'T' + formik.values.starttime)

    isPast = !!(
      selectedDateTime < currentDateTime ||
      (selectedDateTime.toISOString().split('T')[0] === currentDate &&
        formik.values.starttime < currentTime)
    )
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      {isPast && (
        <div className='text-danger d-flex flex-column align-items-center mb-4'>
          You can not edit ongoing or closed event
        </div>
      )}
      <div className='card-body p-0'>
        <div className='d-flex flex-column align-items-center'>
          {/* Name Field */}
          <div className='form-group'>
            <label
              htmlFor='name'
              style={{marginLeft: '15px', display: 'block', fontWeight: 'bold'}}
            >
              Event Title
            </label>
            <textarea
              id='name'
              name='name'
              autoComplete='off'
              className={`form-control form-control-lg form-control-solid mx-4 mb-4 ${
                formik.touched.name && formik.errors.name ? 'is-invalid' : ''
              }`}
              placeholder='Event Title'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{width: '324px'}}
              maxLength={100}
              disabled={isPast}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='invalid-feedback' style={{marginLeft: '14px', marginTop: '-4px'}}>
                {formik.errors.name}
              </div>
            )}
          </div>
          <div className='form-group' style={{display: 'flex'}}>
            <div className='form-group'>
              <label
                htmlFor='starttime'
                style={{marginLeft: '15px', display: 'block', fontWeight: 'bold'}}
              >
                Start Date
              </label>
              <DatePicker
                id='startDate'
                name='startDate'
                autoComplete='off'
                onChange={(date) => {
                  formik.setFieldValue('startDate', parseDateToYYYYMMDD(date))}}
                selected={formik.values.startDate ? moment(formik.values.startDate).toDate() : null}
                popperPlacement='auto'
                dateFormat='dd/MM/yy'
                className={`form-control custom-datepicker form-control-lg form-control-solid  mx-4 my-1 ${
                  formik.touched.startDate && formik.errors.startDate ? 'is-invalid' : ''
                }`}
                placeholder='Start Date'
                onBlur={formik.handleBlur}
                showYearDropdown
                showMonthDropdown
                onKeyDown={(e) => e.preventDefault()}
                minDate={edit ? tomorrow : new Date()}
                maxDate={formik.values.endDate}
                disabled={
                  !!(
                    formik.values.startDate &&
                    formik.values.startDate < new Date().toISOString().split('T')[0]
                  ) || isPast
                }
              />
              {formik.touched.startDate && formik.errors.startDate && (
                <div
                  className='invalid-feedback'
                  style={{marginLeft: '14px', marginTop: '-4px', width: '150px', display: 'block'}}
                >
                  {formik.errors.startDate}
                </div>
              )}
            </div>

            <div className='form-group' style={{display: 'flex', flexDirection: 'column'}}>
              <label
                htmlFor='starttime'
                style={{marginLeft: '15px', display: 'block', fontWeight: 'bold'}}
              >
                End Date
              </label>
              <DatePicker
                id='endDate'
                name='endDate'
                autoComplete='off'
                onChange={(date) => formik.setFieldValue('endDate', parseDateToYYYYMMDD(date))}
                selected={formik.values.endDate ? moment(formik.values.endDate).toDate() : null}
                popperPlacement='auto'
                dateFormat='dd/MM/yy'
                className={`form-control custom-datepicker form-control-lg form-control-solid  mx-4 my-1 ${
                  formik.touched.endDate && formik.errors.endDate ? 'is-invalid' : ''
                }`}
                placeholder='End Date'
                onBlur={formik.handleBlur}
                showYearDropdown
                showMonthDropdown
                onKeyDown={(e) => e.preventDefault()}
                minDate={formik.values.startDate ? moment(formik.values.startDate).toDate() : null}
                disabled={
                  formik.values.startDate === '' ||
                  (formik.values.startDate &&
                    formik.values.startDate < new Date().toISOString().split('T')[0]) ||
                  isPast
                }
              />

              {formik.touched.endDate && formik.errors.endDate && (
                <div
                  className='invalid-feedback'
                  style={{marginLeft: '14px', marginTop: '-4px', width: '150px', display: 'block'}}
                >
                  {formik.errors.endDate}
                </div>
              )}
            </div>
          </div>

          {/* Start Time Field */}
          <div style={{display: 'flex'}}>
            <div className='form-group'>
              <label htmlFor='starttime' style={{marginLeft: '15px', fontWeight: 'bold'}}>
                Start Time
              </label>
              <input
                type='time'
                id='starttime'
                name='starttime'
                autoComplete='off'
                className={`form-control form-control-lg form-control-solid mx-4 my-2 ${
                  formik.touched.starttime && formik.errors.starttime ? 'is-invalid' : ''
                }`}
                value={formik.values.starttime}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{width: '150px'}}
                disabled={formik.values.startDate === '' || formik.values.endDate === '' || isPast}
              />
              {formik.touched.starttime && formik.errors.starttime && (
                <div
                  className='invalid-feedback'
                  style={{marginLeft: '14px', marginTop: '-4px', width: '150px'}}
                >
                  {formik.errors.starttime}
                </div>
              )}
            </div>
            <div className='form-group'>
              <label htmlFor='starttime' style={{marginLeft: '15px', fontWeight: 'bold'}}>
                End Time
              </label>
              <input
                type='time'
                id='endtime'
                name='endtime'
                autoComplete='off'
                className={`form-control form-control-lg form-control-solid mx-4 my-2 ${
                  formik.touched.endtime && formik.errors.endtime ? 'is-invalid' : ''
                }`}
                value={formik.values.endtime}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{width: '150px'}}
                disabled={formik.values.startDate === '' || formik.values.endDate === '' || isPast}
              />
              {formik.touched.endtime && formik.errors.endtime && (
                <div
                  className='invalid-feedback'
                  style={{marginLeft: '14px', marginTop: '-4px', width: '150px'}}
                >
                  {formik.errors.endtime}
                </div>
              )}
            </div>
          </div>

          {/* Location Field */}
          <div className='form-group'>
            <label
              htmlFor='location'
              style={{marginLeft: '15px', display: 'block', fontWeight: 'bold'}}
            >
              Event Location
            </label>
            <textarea
              id='location'
              name='location'
              autoComplete='off'
              className={`form-control form-control-lg form-control-solid mx-4 mb-4 ${
                formik.touched.location && formik.errors.location ? 'is-invalid' : ''
              }`}
              placeholder='Event Location'
              value={formik.values.location}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{width: '324px'}}
              disabled={isPast}
            />
            {formik.touched.location && formik.errors.location && (
              <div className='invalid-feedback' style={{marginLeft: '14px', marginTop: '-4px'}}>
                {formik.errors.location}
              </div>
            )}
          </div>

          {/* Description Field */}
          <div className='form-group'>
            <label
              htmlFor='description'
              style={{marginLeft: '15px', display: 'block', fontWeight: 'bold'}}
            >
              Event Description
            </label>
            <textarea
              id='description'
              name='description'
              autoComplete='off'
              className={`form-control form-control-lg form-control-solid mx-4 mb-4 ${
                formik.touched.description && formik.errors.description ? 'is-invalid' : ''
              }`}
              placeholder='Event Description'
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{width: '324px'}}
              maxLength={2500}
              disabled={isPast}
            />
            {formik.touched.description && formik.errors.description && (
              <div className='invalid-feedback' style={{marginLeft: '14px', marginTop: '-4px'}}>
                {formik.errors.description}
              </div>
            )}
          </div>

          {/* Event Link Field */}
          <div className='form-group'>
            <label
              htmlFor='eventLink'
              style={{marginLeft: '15px', display: 'block', fontWeight: 'bold'}}
            >
              Event Link
            </label>
            <input
              type='text'
              id='eventLink'
              name='eventLink'
              autoComplete='off'
              className={`form-control form-control-lg form-control-solid  mx-4 mb-4 ${
                formik.touched.eventLink && formik.errors.eventLink ? 'is-invalid' : ''
              }`}
              placeholder='Event Link'
              value={formik.values.eventLink}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{width: '324px'}}
              disabled={isPast}
            />
            {formik.touched.eventLink && formik.errors.eventLink && (
              <div className='invalid-feedback' style={{marginLeft: '14px', marginTop: '-4px'}}>
                {formik.errors.eventLink}
              </div>
            )}
          </div>

          {/* Image Field */}
          <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px'}}>
            {editImage.map((item: any, index) => (
              <div key={index} style={{position: 'relative', marginBottom: '10px'}}>
                <img src={item?.url} alt={`Preview ${index + 1}`} className='w-80px h-80px' />
                <button
                  type='button'
                  onClick={() => handlePreviewImage(index)}
                  style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    background: '#FFFFFF',
                    color: 'black',
                    border: 'none',
                    cursor: 'pointer',
                    borderRadius: '5px',
                  }}
                  disabled={isPast}
                >
                  ✖
                </button>
              </div>
            ))}
            {images.map((item, index) => (
              <div key={index} style={{position: 'relative', marginBottom: '10px'}}>
                <img
                  src={URL.createObjectURL(item)}
                  alt={`Preview ${index + 1}`}
                  className='w-80px h-80px'
                />
                <button
                  type='button'
                  onClick={() => handleUploadImage(index)}
                  style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    background: '#FFFFFF',
                    color: 'black',
                    border: 'none',
                    cursor: 'pointer',
                    borderRadius: '5px',
                  }}
                  disabled={isPast}
                >
                  ✖
                </button>
              </div>
            ))}
          </div>
          <div className='form-group'>
            <div className='col-lg-8'>
              <label
                htmlFor='profile_img'
                className={`btn btn-sm btn-danger ${isPast ? 'opacity-75' : ''}`}
                style={{whiteSpace: 'nowrap'}}
              >
                <KTIcon iconName='message-add' className='fs-3' />
                Upload Image
              </label>
              <input
                className='input-preview__src w-100px h-100px'
                type='file'
                multiple
                autoComplete='off'
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const errorMessages: string[] = []
                  if (imageCount === 7) {
                    toast.error('Cannot add more than 7 images.')
                    return
                  }

                  if (event.target.files && event.target.files.length > 0) {
                    const newImages = Array.from(event.target.files)

                    setSeqCode((prevSeqCode) => {
                      let incrementedSeqCode = prevSeqCode !== undefined ? prevSeqCode + 1 : 1

                      const updatedImages = newImages
                        .map((newImage, index) => {
                          if (newImage.size > 2 * 1024 * 1024) {
                            errorMessages.push('Image size must be less than 2MB.')
                            return null
                          }
                          const allowedExtensions = ['jpeg', 'jpg', 'png', 'dng', 'HEIC']
                          const fileExtension = newImage.name.split('.').pop()?.toLowerCase()

                          if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
                            errorMessages.push('Invalid file type.')
                            return null
                          }
                          const customFileName = `i${incrementedSeqCode + index}.${
                            newImage.type.split('/')[1]
                          }`
                          const newFile = new File([newImage], customFileName, {
                            type: newImage.type,
                          })
                          return newFile
                        })
                        .filter(Boolean) as File[]

                      if (imageCount + updatedImages.length > 7) {
                        toast.error('Cannot add more than 7 images.')
                        return
                      }

                      setImages((prevImages) => [...prevImages, ...updatedImages])

                      if (showImage) {
                        setShowImage(false)
                      }
                      if (errorMessages.length > 0) {
                        if (errorMessages.includes('Image size must be less than 2MB.')) {
                          toast.error('Image size must be less than 2MB.')
                        }
                        if (errorMessages.includes('Invalid file type.')) {
                          toast.error('Invalid file type.')
                        }
                        return
                      }

                      return incrementedSeqCode + newImages.length
                    })
                  }
                }}
                id='profile_img'
                accept='image/png, image/jpeg'
                disabled={isPast}
              />
            </div>
          </div>
          {}
          {showImage && (
            <div style={{marginLeft: '10px', color: 'red'}}>
              Note: Please upload atleast one image
            </div>
          )}

          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: '10px',
              marginTop: '20px',
            }}
          >
            {youtubeUrl &&
              youtubeUrl.map((item: any, index) => (
                <div key={index} style={{position: 'relative', marginBottom: '10px'}}>
                  <img
                    key={index}
                    src={item.thumbnailURL}
                    alt={`Thumbnail ${index + 1}`}
                    className='h-80px w-80px'
                  />
                  <button
                    type='button'
                    onClick={() => handlePreviewWeburl(index)}
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      background: '#FFFFFF',
                      color: 'black',
                      border: 'none',
                      cursor: 'pointer',
                      borderRadius: '5px',
                    }}
                    disabled={isPast}
                  >
                    ✖
                  </button>
                </div>
              ))}
            {imageResult.length !== 0 &&
              imageResult?.map((image: any, index) => (
                <div key={index} style={{position: 'relative', marginBottom: '10px'}}>
                  <img
                    key={index}
                    src={image}
                    alt={`Thumbnail ${index + 1}`}
                    className='h-80px w-80px'
                  />
                  <button
                    type='button'
                    onClick={() => handleUploadWeburl(index)}
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      background: '#FFFFFF',
                      color: 'black',
                      border: 'none',
                      cursor: 'pointer',
                      borderRadius: '5px',
                    }}
                    disabled={isPast}
                  >
                    ✖
                  </button>
                </div>
              ))}
          </div>
          <div className='form-group d-flex flex-column align-items-center'>
            {youtubeInput ? (
              <React.Fragment>
                <label htmlFor='weburl' style={{marginLeft: '15px', alignSelf: 'baseline'}}>
                  Youtube Link
                </label>
                <input
                  type='text'
                  id='weburl'
                  name='weburl'
                  autoComplete='off'
                  className={`form-control form-control-lg form-control-solid mx-4 mb-4 ${
                    formik.touched.weburl && formik.errors.weburl ? 'is-invalid' : ''
                  }`}
                  placeholder='Youtube Link'
                  value={inputValue}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  style={{width: '300px'}}
                  disabled={isPast}
                />
                {youtubeError && (
                  <div
                    className='text-danger'
                    style={{marginLeft: '14px', marginTop: '-4px', marginBottom: '5px'}}
                  >
                    {youtubeError}
                  </div>
                )}
                <button
                  type='button'
                  className='btn btn-sm btn-danger '
                  onClick={handleAddLink}
                  disabled={isPast}
                >
                  <KTIcon iconName='youtube' className='fs-3' /> Add Youtube Link
                </button>
              </React.Fragment>
            ) : (
              <button
                type='button'
                className='btn btn-sm btn-danger '
                onClick={() => setyoutubeInput(true)}
              >
                <KTIcon iconName='youtube' className='fs-3' /> Upload Youtube Link
              </button>
            )}
          </div>
          {/* Submit Button */}
          <button
            type='submit'
            className='btn btn-primary font-weight-bold px-9 py-4 my-3'
            disabled={loading || isPast}
          >
            {loading ? (
              <div className='d-flex align-items-center'>
                <span>Submitting</span>
                <span
                  className='spinner-border spinner-border-sm mx-2'
                  role='status'
                  aria-hidden='true'
                ></span>
              </div>
            ) : Object.keys(selectedRow).length > 0 ? (
              'Save Changes'
            ) : (
              'Create Event'
            )}
          </button>
        </div>
      </div>
      <ToastContainer />
    </form>
  )
}

export default AddEditEventBody
